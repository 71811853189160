import CustomFieldsActions from '@/components/customfields/CustomFieldsActions';
const alertSubscription = {
	id: 'alertSubscription',
	selectionType: 'single',
	label: 'actions.visit.alertSubscription',
	functionality: 'CREATE_SUBSCRIPTION',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		// Sample code to open a model dialog
		const row = registries[0];
		// Get PK for the header
		/*const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;*/
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-alertSubscription-' + model.name + '-show', row);
	}
};

const visitTransitions = {
	id: 'visitTransitions',
	selectionType: 'single',
	label: 'actions.visit.transitions',
	functionality: 'ACTION_VISIT_STOP_TRANSITIONS',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		// Sample code to open a model dialog
		const row = registries[0];
		// Get PK for the header
		/*const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;*/
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-visitTransitions-' + model.name + '-show', row);
	}
};
const vesselInfoFromShip = {
	id: 'vesselInfoFromShip',
	selectionType: 'single',
	label: 'actions.visit.vesselInfoFromShip',
	functionality: 'READ_SHIP',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		const shipPk = { id: row.shipid };
		const pk = window.btoa(JSON.stringify(shipPk));
		const shipUrl = `/ship/read/${pk}/`;

		const params = {
			router: this.$router,
			url: shipUrl,
			params: null,
			query: {},
			replace: false
		};

		this.$store.dispatch('puiRouterGoToUrl', params);
	}
};

const compareWithShipMaster = {
	id: 'compareWithShipMaster',
	selectionType: 'single',
	label: 'actions.visit.compareWithShipMaster',
	functionality: 'READ_SHIP',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		let row = registries[0];
		const portAuthorityId = window.localStorage.getItem('workingPortAuthorityId');

		const opts1 = {
			model: 'portauthority',
			filter: {
				groupOp: 'and',
				groups: [],
				rules: [
					{
						field: 'id',
						op: 'eq',
						data: portAuthorityId
					}
				]
			}
		};

		this.$puiRequests.postRequest('/puisearch', opts1, (response) => {
			if (response.data && response.data.data[0]) {
				const PAshiplist = response.data.data[0].ownlistships;

				if (PAshiplist == true) {
					const opts2 = {
						model: 'ship',
						filter: {
							groupOp: 'and',
							groups: [],
							rules: [
								{ field: 'imo', op: 'eq', data: row.shiptechnicaldata.imo },
								{ field: 'nextversionid', op: 'nu', data: null },
								{ field: 'portauthorityid', op: 'eq', data: portAuthorityId }
							]
						}
					};

					this.$puiRequests.postRequest('/puisearch', opts2, (response) => {
						if (response.data && response.data.data[0]) {
							row.ship = response.data.data[0];
							row.isAction = true;
							this.$puiEvents.$emit('pui-modalDialogForm-compareWithShipMaster-' + model.name + '-show', row);
						}
					});
				} else {
					const opts2 = {
						model: 'ship',
						filter: {
							groupOp: 'and',
							groups: [],
							rules: [
								{ field: 'imo', op: 'eq', data: row.shiptechnicaldata.imo },
								{ field: 'nextversionid', op: 'nu', data: null },
								{ field: 'portauthorityid', op: 'nu', data: null }
							]
						}
					};

					this.$puiRequests.postRequest('/puisearch', opts2, (response) => {
						if (response.data && response.data.data[0]) {
							row.ship = response.data.data[0];
							row.isAction = true;
							this.$puiEvents.$emit('pui-modalDialogForm-compareWithShipMaster-' + model.name + '-show', row);
						}
					});
				}
			}
		});

		// Get PK for the header
		/*const objectPk = {};
			for (var index in model.columns) {
				const column = model.columns[index];
				if (column.isPk) {
					objectPk[column.name] = registries[0][column.name];
				}
			}
			row.headerPk = objectPk;*/
	}
};

const finishVisit = {
	id: 'finishVisit',
	selectionType: 'single',
	label: 'actions.visit.finishVisit',
	functionality: 'ACTION_FINISH_VISIT',
	checkAvailability: function (registries) {
		console.log(registries);

		return registries[0] && (registries[0].statusid == 'IN' || registries[0].status == 'IN');
	},
	runAction: function (action, model, registries) {
		const row = registries[0];

		// Sample code to open a model dialog
		// Get PK for the header
		/*const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;*/
		//row.rows = registries;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-finishVisit-' + model.name + '-show', row);
	}
};

const reinitiateVisit = {
	id: 'reinitiateVisit',
	selectionType: 'single',
	label: 'actions.visit.reinitiateVisit',
	functionality: 'ACTION_REINITIATE_VISIT',
	checkAvailability: function (registries) {
		console.log(registries);

		return registries[0] && (registries[0].statusid == 'FI' || registries[0].status == 'FI');
	},
	runAction: function (action, model, registries) {
		const row = registries[0];

		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-reinitiateVisit-' + model.name + '-show', row);
	}
};

const linkBookingStop = {
	id: 'linkBookingStop',
	selectionType: 'single',
	label: 'actions.visit.linkBookingStop',
	functionality: 'ACTION_LINK_BOOKING_STOP',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			let status = this.$store.getters.stopsStatusValues;
			const statusArray = Object.keys(status).map((code) => ({
				code,
				...status[code]
			}));
			let order = statusArray.filter((status) => status.stopstatuscode == 'FI')[0].orderby;
			let filteredStatus = statusArray.filter((status) => status.orderby < order);
			let editableStatus = filteredStatus.map((status) => status.stopstatuscode);
			const isEditable = editableStatus.some((element) => element === registries[0].statusid);
			return isEditable;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-linkBookingStop-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [
		alertSubscription,
		visitTransitions,
		vesselInfoFromShip,
		finishVisit,
		reinitiateVisit,
		CustomFieldsActions.configFields('visit'),
		linkBookingStop
	],
	formactions: [visitTransitions, vesselInfoFromShip, compareWithShipMaster, reinitiateVisit, finishVisit]
};
