<template>
	<div class="visitFormHeader puiformheader">
		<v-layout wrap>
			<v-flex xs12 sm6 md3 lg3>
				<pui-field :label="this.$t('header.visit.portcallnumber')" :value="getPortCallNumber"></pui-field>
			</v-flex>
			<v-flex xs12 sm6 md4 lg4>
				<pui-field :label="this.$t('header.visit.portname')" :value="getPortName"></pui-field>
			</v-flex>
			<v-flex xs12 sm6 md4 lg4>
				<pui-field :label="this.$t('header.visit.vesselname')" :value="getVesselName"></pui-field>
			</v-flex>
			<v-flex xs12 sm6 md1 lg1>
				<div>
					<span class="notificationIcon" v-if="model.incidentcount > 0">
						<i :title="this.incidentDescription" class="fak fa-solid-alert"></i>
					</span>
					<span class="notificationIcon" v-if="model.repaircount > 0">
						<i :title="this.repairDescription" class="fas fa-hammer"></i>
					</span>
					<span class="notificationIcon" v-if="model.chatcount > 0">
						<i :title="this.$t('header.visit.chatNotification')" class="fak fa-solid-coment"></i>
					</span>
					<span class="notificationIcon" v-if="model.stopcount == 0">
						<i :title="this.stopDescription" class="fak fa-notification-no-berthing"></i>
					</span>
					<span class="notificationIcon" v-if="model.inconsistencycount > 0">
						<i :title="this.$t('header.visit.inconsistencyNotification')" class="fa-kit fa-solid-anomalies"></i>
					</span>
				</div>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'visitFormHeader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'visit',
			closedStatus: 2,
			incidentDescription: this.$t('header.visit.incidentNotification'),
			repairDescription: this.$t('header.visit.repairNotification'),
			stopDescription: this.$t('header.visit.noStopNotification')
		};
	},
	computed: {
		getPortCallNumber() {
			return this.model && this.model.portcallnumber ? this.model.portcallnumber : '';
		},
		getPortName() {
			return this.model && this.model.portname ? this.model.portname : '';
		},
		getVesselName() {
			return this.model && this.model.vesselname ? this.model.vesselname : '';
		}
	},
	methods: {},
	mounted() {}
};
</script>
<style scoped>
.notificationIcon {
	color: red;
	padding-right: 10px;
}
</style>
